export function useBunnyImage() {
  const bunnyUrl = (src: string) => {
    // console.log('src', src)
    const bunnyUrlFromAltoUrl = src.replace('https://alto-live.s3.amazonaws.com', '')
    // console.log('bunnyUrlFromAltoUrl', bunnyUrlFromAltoUrl)
    return bunnyUrlFromAltoUrl
  }

  return {
    bunnyUrl,
  }
}
